import { useTranslate } from "../../../../customHooks";
import { composeValidators, firewallNameKeyNotAllowed, isAlphaNumeric, maxLength } from "../../../../utils/validate";
import { usePermissions } from '../../../../customHooks';
import { ApplicationConfigManager, SupportedRegion } from "../../../../types";
import { useCallback, useMemo } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, Select, SubmitButton } from "@panwds/react-form";
import { useGetRegionsQuery } from "../../../../redux/services/regions-service";
import { useFilteredPermissions } from "../../../../customHooks/useFilteredPermissions";
import { filter, find, isEmpty, some } from 'lodash';
import { getFirewallName, getFirewallTags } from "../../firewallsUtil";
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, LoadingPanel } from "@panwds/react-ui";
import { TagsWithAdd } from "../../../../components/FormElements";
import { firewallStyles } from "../../style";
import { FirewallUpdateBanner } from "../../FirewallUpdateBanner";

const FirewallGeneralInfo = (props: {canEdit: boolean, firewallData: Record<string, any>, isLoading: boolean, refreshFirewallData: () => void}) => {
    const { filteredChangeProtectionOptions } = useFilteredPermissions();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const region = new URLSearchParams(location.search).get('region');
    const [updaFirewall] = useUpdateFirewallMutation();
    const firewallUpdating = ["CREATING", "UPDATING", "CREATE_FAIL", "DELETING"].includes(props?.firewallData?.Status?.FirewallStatus);
    const classes = firewallStyles();

    const hasGFA = find(filteredChangeProtectionOptions, { value: "GlobalFirewallAdmin" });

    if (hasGFA) {
        hasGFA.disabled = true;
        // Find entries missing in user permission that are present in firewall's change protection
        const missingEntries = filter(props?.firewallData?.Firewall?.ChangeProtection, str => {
            const [text, value] = str.split("/");
            return !some(filteredChangeProtectionOptions, { value: str })
        });

        // Add missing entries to change protection items if user has GFA permission
        filteredChangeProtectionOptions.push(...missingEntries.map(str => {
            return { text: str, value: str };
        }));

    } else {
        filteredChangeProtectionOptions.push({
            text: "Global Firewall Admin",
            value: "GlobalFirewallAdmin",
            disabled: true
        })
    }

    const { data: zones, error, isLoading: isZonesLoading, refetch: refetchZones } = useGetRegionsQuery(region, {
        skip: !region,
    });

    const regionItems = ApplicationConfigManager.getInstance().getSupportedRegions().map((region: SupportedRegion) => ({
        text: region.RegionDisplayName,
        value: region.RegionCode
    }));
    const regionDisplayName = find(regionItems, (regionItem) => (regionItem.value === region));

    const config = {
        Firewall: ['FirewallName', 'FirewallId', 'Region', 'CustomerZoneIdList', 'ChangeProtection', 'Description', 'Tags', 'UpdateToken', 'DeploymentUpdateToken'],
    };

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config.
        // Extracts fields needed for firewall general info form.
        const newStateWithConfig = extractFieldsWithConfig(props?.firewallData, config);
        return {
            Firewall: {
                ...newStateWithConfig.Firewall,
                Region: (regionDisplayName) ? regionDisplayName['text'] : region || '',
                FirewallName: getFirewallName(newStateWithConfig),
                Tags: newStateWithConfig.Firewall?.Tags?.filter((tag) => tag?.Key !== "FirewallName") || [],
            }
        }
    }, []);

      /**
    * Transforms the form data for firewall update submission.
    * This function processes the form data, applies necessary transformations,
    * and prepares the data structure required for the firewall general info update.
    */
    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        const transformedData = {
            ...formData.Firewall,
            Tags: getFirewallTags(formData.Firewall.Tags, formData.Firewall.FirewallName),
            Region: region
        }
        delete transformedData['FirewallName'];
        return transformedData;
    }, []);

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });
    const {
        formState: { dirtyFields }, control, reset,
    } = formMethods
    const onSubmit = useCallback(
        async (data) => {
            try {
                const result = await updaFirewall({
                    payload: transformFormSubmitValue(data)
                }).unwrap();
                if (result) {
                    await props.refreshFirewallData();
                }
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, { toastId: "update-firewall" });
                reset();
            }
        }, [history, permissions, formMethods]
    );

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    return (
        <div className="tw-relative">
        {props?.isLoading &&
            <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                <div className="tw-w-6 tw-h-6">
                    <LoadingPanel />
                </div>
            </div>
        }
            {!props?.canEdit &&
                <FirewallUpdateBanner content={translate('resources.firewallsV2.cantEditFirewall')} />
            }
            {firewallUpdating &&
                <FirewallUpdateBanner refresh={props.refreshFirewallData} />
            }
            <div className={classes.fwEditHeader}>{translate(`resources.firewallsV2.generalInfo`)}</div>
            <div className={!props?.canEdit || firewallUpdating || !permissions?.UpdateFirewall ? classes.fwReadOnly : ""}>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <FormLayout>
                            <Input
                                label={translate(`resources.firewallsV2.firewallName`)}
                                name="Firewall.FirewallName"
                                dataMetrics="cloudngfw-firewall-edit-name"
                                description={translate(`resources.firewallsV2.firewallNameEditDescription`)}
                                disabled
                                requiredAsterisk
                                register={{ required: true, validate: (value) => value === "" || composeValidators(isAlphaNumeric, maxLength(128))(value) }}
                            />
                            <Input
                                label={translate(`resources.firewallsV2.firewallId`)}
                                name="Firewall.FirewallId"
                                dataMetrics="cloudngfw-firewall-edit-firewallId"
                                disabled
                                description={translate(`resources.firewallsV2.firewallIdEditDescription`)}
                            />
                            <Input
                                label={translate(`resources.firewallsV2.region`)}
                                name="Firewall.Region"
                                dataMetrics="cloudngfw-firewall-edit-region"
                                description={translate(`resources.firewallsV2.regionEditDescription`)}
                                disabled
                            />
                            <Select
                                items={zones}
                                label={translate(`resources.firewallsV2.availabilityZoneIDs`)}
                                name="Firewall.CustomerZoneIdList"
                                dataMetrics={"cloudngfw-firewall-edit-availability-zone"}
                                isLoading={isZonesLoading}
                                enableMultiSelect
                                simpleFormat
                            />
                            <Select
                                items={filteredChangeProtectionOptions}
                                label={translate(`resources.firewallsV2.changeProtection`)}
                                name="Firewall.ChangeProtection"
                                dataMetrics={"cloudngfw-firewall-edit-change-protection"}
                                enableMultiSelect
                                simpleFormat
                                requiredAsterisk
                            />
                            <Input
                                label={translate(`resources.firewallsV2.description`)}
                                name="Firewall.Description"
                                dataMetrics="cloudngfw-firewall-edit-description"
                                register={{ required: false }}
                            />
                            <Controller
                                name="Firewall.Tags"
                                control={control}
                                render={({ field }) => (
                                    <TagsWithAdd label={translate('generic.tags')} {...field} validate={firewallNameKeyNotAllowed} />
                                )}
                            />
                            <div className={classes.formFooterDivider}/>
                            <div className="tw-flex tw-justify-end">
                                <Button addClassName="tw-mr-2" onClick={handleCancel} disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.cancel`)}
                                </Button>
                                <SubmitButton disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.save`)}
                                </SubmitButton>
                            </div>
                        </FormLayout>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default FirewallGeneralInfo;
