import { useMemo } from 'react';
import useTranslate from './useTranslate';
import usePermissions from './usePermissions';

export const useFilteredPermissions = () => {
    const translate = useTranslate();
    const { rawPermissions } = usePermissions();

    const filteredChangeProtectionOptions = useMemo(() => {
        return rawPermissions
            .filter((permission: any) => permission.Policy.includes('FirewallAdmin'))
            .map((filteredPermission: any) => {
                // Format string based on the presence of AccountId
                const formattedValue = filteredPermission.AccountId
                    ? `${filteredPermission.AccountId}/${filteredPermission.Policy}`
                    : filteredPermission.Policy;

                // Check if formattedValue contains 'GlobalFirewallAdmin'
                const text = formattedValue.includes('GlobalFirewallAdmin')
                    ? translate(`resources.firewallsV2.globalFirewallAdmin`)
                    : formattedValue;

                // Return an object with text and value properties
                return { text, value: formattedValue };
            });
    }, [rawPermissions, translate]);

    const globalFirewallAdminOption = useMemo(() => {
        return filteredChangeProtectionOptions.find((option: any) => option.value.includes('GlobalFirewallAdmin'));
    }, [filteredChangeProtectionOptions]);

    return { filteredChangeProtectionOptions, globalFirewallAdminOption };
};
