import { Banner } from "@panwds/react-ui";
import { useTranslate } from "../../customHooks";
import { RedoIcon } from "@panwds/icons";

export const FirewallUpdateBanner = (props: any) => {
    const translate = useTranslate();
    return (
        <div className="tw-mb-2">
         <Banner
            actions={(props?.refresh) ? <RedoIcon onClick={() => props.refresh()} size="sm"/> : null}
            type="inline"
            appearance="warning"
            showIcon>
            {props?.content || translate('resources.firewallsV2.firewallUpdating')}
        </Banner>
    </div>
    );
};
