import React, { useEffect, useMemo, useState } from "react";
import { useTranslate } from "../../../../../customHooks";
import { useLazyGetRulesByRuleStackQuery } from "../../../../../redux/services/rules-service";
import { toast } from "../../../../../components";
import { LoadingPanel, Tooltip } from "@panwds/react-ui";
import Emitter from "../../../../../eventEmitter";
import { dataProvider } from "../../../../../dataProvider";
import * as DataTypes from "../../../../../api/FwaasDataTypes";
import { CircleCheckIcon, ExclamationCircleIcon } from "@panwds/icons";

interface SecurityPoliciesProps {
    firewall: Record<string, any>;
    status: Record<string, any>;
    managedBy: string;
    styles: any;
}

const SecurityPoliciesOverview: React.FC<SecurityPoliciesProps> = ({
    firewall,
    status,
    managedBy,
    styles,
}) => {
    const translate = useTranslate();
    const isRuleStack = managedBy === "Local Rulestack";
    const isGlobalRuleStack = managedBy === "Global Rulestack";
    const isSCMOrPanorama = managedBy === "SCM" || managedBy === "Panorama";
    const isSCM = managedBy === "SCM";
    const [totalRules, setTotalRules] = useState<number>(0);
    let [trigger, { data, error, isLoading: rulesLengthLoading }] =
        useLazyGetRulesByRuleStackQuery();
    const Region =
        useMemo(
            () => new URLSearchParams(location.search).get("region"),
            [location.search]
        ) || undefined;
    const [links, setLinks] = useState<any>({});

    const fetchAllRules = async (nextToken?: string) => {
        try {
            data = await trigger({
                RuleStackName: firewall?.RuleStackName,
                RuleListName: "LocalRule",
                NextToken: nextToken,
                Region,
            }).unwrap();
            const newRulesCount = data.ruleEntries.length;
            setTotalRules((prevCount: any) => prevCount + newRulesCount);

            if (data.nextToken) {
                fetchAllRules(data.nextToken);
            }
        } catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, {
                toastId: "security-policies-overview-rules-list",
            });
        }
    };

    const fetchAllGlobalRules = async (
        preNextToken?: string,
        postNextToken?: string
    ) => {
        try {
            if (preNextToken) {
                const preData = await trigger({
                    RuleStackName: firewall?.GlobalRuleStackName,
                    RuleListName: "PreRule",
                    NextToken: preNextToken,
                    Region,
                }).unwrap();
                const newRulesCount = preData.ruleEntries.length;
                setTotalRules((prevCount) => prevCount + newRulesCount);

                if (preData.nextToken) {
                    await fetchAllGlobalRules(preData.nextToken, postNextToken);
                } else if (postNextToken) {
                    await fetchAllGlobalRules(undefined, postNextToken);
                }
            } else if (postNextToken) {
                const postData = await trigger({
                    RuleStackName: firewall?.GlobalRuleStackName,
                    RuleListName: "PostRule",
                    NextToken: postNextToken,
                    Region,
                }).unwrap();
                const newRulesCount = postData.ruleEntries.length;
                setTotalRules((prevCount) => prevCount + newRulesCount);

                if (postData.nextToken) {
                    await fetchAllGlobalRules(undefined, postData.nextToken);
                }
            } else {
                const preData = await trigger({
                    RuleStackName: firewall?.GlobalRuleStackName,
                    RuleListName: "PreRule",
                    Region,
                }).unwrap();
                const postData = await trigger({
                    RuleStackName: firewall?.GlobalRuleStackName,
                    RuleListName: "PostRule",
                    Region,
                }).unwrap();
                const newRulesCount =
                    preData.ruleEntries.length + postData.ruleEntries.length;
                setTotalRules((prevCount) => prevCount + newRulesCount);

                if (preData.nextToken) {
                    await fetchAllGlobalRules(
                        preData.nextToken,
                        postData.nextToken
                    );
                } else if (postData.nextToken) {
                    await fetchAllGlobalRules(undefined, postData.nextToken);
                }
            }
        } catch (error: any) {
            toast.error(`${error?.code}: ${error?.error}`, {
                toastId: "security-policies-overview-rules-list",
            });
        }
    };

    const loadLinkData = () => {
        let apiCall: { resource: string; url?: string; payload?: any } = {
            resource: "settings",
            payload: { panorama: true },
        };
        if (process.env.REACT_APP_SHOW_CM === "true") {
            apiCall = {
                resource: "integrations",
            };
        }

        dataProvider
            .describe(
                apiCall.resource,
                apiCall.url || "",
                apiCall?.payload || {}
            )
            .then(async (response: DataTypes.IFwaasApiResponse) => {
                setLinks(response?.data?.Links);
            })
            .catch((e: any) => {
                toast.error(e?.error?.error, { toastId: "support-describe" });
            });
    };

    useEffect(() => {
        if (isSCM) {
            loadLinkData();
        }
    }, [isSCM]);

    const getUrlLink = (LinkId: string) => {
        const url = links?.[LinkId]?.CloudManager?.Url;
        return url;
    };

    useEffect(() => {
        if (isRuleStack) {
            fetchAllRules();
        }
    }, [isRuleStack]);

    useEffect(() => {
        if (isGlobalRuleStack) {
            fetchAllGlobalRules();
        }
    }, [isGlobalRuleStack]);

    const handleRuleStackClick = (ruleStackName: string, type: string) => {
        const region = new URLSearchParams(window.location.search).get(
            "region"
        );
        if (region) {
            localStorage.setItem("currentRegion", region); // Store the region in localStorage
        }
        Emitter.emit("regionChanged", true); // Emit the region change event
        if (type === "local") {
            window.location.href = `/rulestacks/${ruleStackName}`;
        } else if (type === "global") {
            window.location.href = `/rulestacks`;
        }
    };

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>
                {translate(`resources.firewallsV2.policyManager`)}
            </h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>
                        {translate(`resources.firewallsV2.managedBy`)}
                    </strong>
                    <span className={styles.flexRowValues}>{managedBy}</span>
                </div>
                {isRuleStack && (
                    <>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(
                                    `resources.firewallsV2.localRulestack`
                                )}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {firewall?.RuleStackName ? (
                                    <a
                                        href="#"
                                        onClick={() =>
                                            handleRuleStackClick(
                                                firewall?.RuleStackName,
                                                "local"
                                            )
                                        }
                                        className="text-blue-500"
                                    >
                                        {firewall?.RuleStackName}
                                    </a>
                                ) : (
                                    "N/A"
                                )}
                            </span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(
                                    `resources.firewallsV2.localRulestackStatus`
                                )}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {status?.RuleStackStatus}
                            </span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(
                                    `resources.firewallsV2.numberOfRules`
                                )}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {rulesLengthLoading ? (
                                    <div className="tw-w-6 tw-h-6">
                                        <LoadingPanel />
                                    </div>
                                ) : totalRules !== 0 ? (
                                    <a
                                        href={`/rulestacks/${firewall?.RuleStackName}/rules`}
                                        className="text-blue-500"
                                    >
                                        {" "}
                                        {totalRules}
                                    </a>
                                ) : (
                                    totalRules
                                )}
                            </span>
                        </div>
                    </>
                )}
                {isGlobalRuleStack && (
                    <>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(
                                    `resources.firewallsV2.globalRulestack`
                                )}
                            </strong>
                            <span className={styles.flexRowValues}>
                                <span className={styles.flexRowValues}>
                                    {firewall?.GlobalRuleStackName ? (
                                        <a
                                            href="#"
                                            onClick={() =>
                                                handleRuleStackClick(
                                                    firewall?.GlobalRuleStackName,
                                                    "global"
                                                )
                                            }
                                            className="text-blue-500"
                                        >
                                            {firewall?.GlobalRuleStackName}
                                        </a>
                                    ) : (
                                        "N/A"
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(
                                    `resources.firewallsV2.globalRulestackStatus`
                                )}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {rulesLengthLoading ? (
                                    <div className="tw-w-6 tw-h-6">
                                        <LoadingPanel />
                                    </div>
                                ) :
                                status?.GlobalRuleStackStatus === "Failed" ? (
                                    <>
                                        <div className="tw-flex">
                                            <Tooltip
                                                label={
                                                    status
                                                        ?.GlobalRuleStackCommitInfo
                                                        ?.CommitMessages?.[0] ||
                                                    ""
                                                }
                                            >
                                                <ExclamationCircleIcon
                                                    size="xs"
                                                    className="tw-text-red-500 dark:tw-text-dark-bg-red"
                                                />
                                            </Tooltip>
                                            &nbsp;{" "}
                                            {status?.GlobalRuleStackStatus}
                                        </div>
                                    </>
                                ) : status?.GlobalRuleStackStatus ===
                                  "Success" ? (
                                    <>
                                        <CircleCheckIcon
                                            size="xs"
                                            className="tw-text-teal-600 dark:tw-text-dark-bg-teal"
                                        />
                                        &nbsp; {status?.GlobalRuleStackStatus}
                                    </>
                                ) : (
                                    status?.GlobalRuleStackStatus
                                )}
                            </span>
                        </div>
                    </>
                )}
                {isSCMOrPanorama && (
                    <>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(`resources.firewallsV2.linkId`)}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {isSCM ? (
                                    <a
                                        target="_blank"
                                        href={getUrlLink(firewall?.LinkId)}
                                    >
                                        {firewall?.LinkId}
                                    </a>
                                ) : (
                                    firewall?.LinkId
                                )}
                            </span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>
                                {translate(`resources.firewallsV2.linkStatus`)}
                            </strong>
                            <span className={styles.flexRowValues}>
                                {firewall?.LinkStatus}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SecurityPoliciesOverview;
