import React, { forwardRef, useMemo } from "react";
import { Table } from "../../../../../components/PANWDSElements";
import { useTranslate } from "../../../../../customHooks";
import { Button, Link, Tooltip } from "@panwds/react-ui";
import { BanIcon, CircleCheckIcon, DeleteIcon } from "@panwds/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: "#33CCB8",
    },
}));

const CustomIncludeExcludeNetworksTable = forwardRef<HTMLInputElement, any>(
    ({ userIDStatus, deleteNetwork, onRowClick, disabled, ...props }, ref) => {
        const { value, setSidePanel } = props;
            const intl = useIntl();

        const translate = useTranslate();
        const classes = useStyles();

        const columns = React.useMemo(
            () => [
                {
                    accessor: "Name",
                    Header: translate(
                        `resources.firewallsV2.userid.customIncludeExcludeNetworkName`
                    ),
                    columnSize: "auto",
                    Cell: ({ row }: any) => {
                        return disabled ? (
                            <span>{row?.original?.Name}</span>
                        ) : (
                            <Button
                                appearance="clear"
                                style={{
                                    whiteSpace: "break-spaces",
                                    paddingLeft: 0,
                                }}
                                onClick={() => onRowClick(row?.original)}
                            >
                                {row?.original?.Name}
                            </Button>
                        );
                    },
                },
                {
                    accessor: "Enabled",
                    Header: translate(
                        `resources.firewallsV2.userid.customIncludeExcludeNetworkEnabled`
                    ),
                    columnSize: "auto",
                    Cell: ({ row }: any) => (
                        <span>
                            {row?.original?.Enabled === true ? (
                                <>
                                    {" "}
                                    <CircleCheckIcon
                                        className={classes.checkIcon}
                                        data-metrics="cloudngfw-users-list-CircleCheck-img"
                                        size="sm"
                                    />{" "}
                                    Yes
                                </>
                            ) : (
                                <>
                                    <BanIcon
                                        size="sm"
                                        className="tw-text-red-600 dark:tw-text-dark-bg-red"
                                    />
                                    No
                                </>
                            )}
                        </span>
                    ),
                },
                {
                    accessor: "DiscoveryInclude",
                    Header: translate(
                        `resources.firewallsV2.userid.customIncludeExcludeNetworkDiscoveryInclude`
                    ),
                    columnSize: "auto",
                    Cell: ({ row }: any) => (
                        <span>
                            {row?.original?.DiscoveryInclude === true ||
                            row?.original?.DiscoveryInclude === translate('resources.firewallsV2.userid.include')
                                ? translate('resources.firewallsV2.userid.include')
                                : translate('resources.firewallsV2.userid.exclude')}
                        </span>
                    ),
                },
                {
                    accessor: "NetworkAddress",
                    Header: translate(
                        `resources.firewallsV2.userid.customIncludeExcludeNetworkNetworkAddress`
                    ),
                    columnSize: "auto",
                    Cell: ({ row }: any) => (
                        <span>{row?.original?.NetworkAddress}</span>
                    ),
                },
                {
                    accessor: "placeholder",
                    Header: translate(`generic.actions`),
                    columnSize: "auto",
                    Cell: ({ row }: any) =>
                        disabled ? (
                            <Tooltip
                                label={translate(
                                    `resources.firewallsV2.userid.deleteDisabled`
                                )}
                            >
                                <DeleteIcon
                                    className="tw-text-gray-400"
                                    size="sm"
                                    data-metrics="icons-delete"
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                label={translate(
                                    `generic.delete`
                                )}
                            >
                                <button
                                    className="tw-mr-2 tw-border-none tw-bg-transparent"
                                    onClick={() => deleteNetwork(row?.original)}
                                >
                                    <DeleteIcon
                                        className="tw-text-gray-700"
                                        size="sm"
                                        data-metrics="icons-delete"
                                    />
                                </button>
                            </Tooltip>
                        ),
                    noTooltip: true,
                    disableSortBy: true,
                    sticky: true,
                },
            ],
            [userIDStatus, translate, classes.checkIcon, deleteNetwork, onRowClick, disabled]
        );

        const batchActions = useMemo(() => {
            return {
                rightControl: (
                    <>
                        {/* TODO: for batch action of delete */}
                        {/* <Button
                            dataTestId="cloudngfw-user-id-delete-btn"
                            // onClick={deleteActionForSelectedEntries}
                            appearance="primary"
                            >
                            {translate(`resources.firewallsV2.userid.delete`)}
                            </Button> */}
                        <Button
                            dataTestId="cloudngfw-firewall-edit-add-CustomIncludeExcludeNetwork"
                            onClick={() => {
                                setSidePanel("createIncludeExclude");
                            }}
                            appearance="primary"
                            disabled={disabled}
                        >
                            {translate(
                                `resources.firewallsV2.userid.addIncludeExcludeRule`
                            )}
                        </Button>
                    </>
                ),
            };
        }, []);

        return (
            <div>
                <Table
                    tableComponentData={value || []}
                    tableComponentColumns={columns}
                    tableComponentEmptyStateProps={{
                        heading: translate(
                            `resources.firewallsV2.userid.customIncludeExcludeNetworkEmptyTitle`
                        ),
                    }}
                    // isMultiselect={!(userIDStatus === "EndpointPending" || userIDStatus === "DisablePending" || userIDStatus === "UpdatePending")}
                    tableBatchAction={batchActions}
                    tableComponentFilterBar={{
                        hideAddFilter: true,
                        filterConfig: undefined,
                    }}
                    tableTitle={translate(
                        `resources.firewallsV2.userid.customIncludeExcludeNetwork`
                    )}
                    tableSubtitle={intl.formatMessage(
                        {
                            id: "resources.firewallsV2.userid.customIncludeExcludeNetworkDescription",
                        },
                        {
                            document: (
                                <Link
                                    dataMetrics="cloudngfw-collector-name-help"
                                    external
                                    href="https://docs.paloaltonetworks.com/pan-os/10-2/pan-os-web-interface-help/device/device-data-redistribution/device-data-redistribution-include-exclude-networks"
                                >
                                    {translate(`generic.learnMore`)}
                                </Link>
                            ),
                        }
                    )}
                />
            </div>
        );
    }
);

export default CustomIncludeExcludeNetworksTable;
