import { CircleCheckIcon, ExclamationCircleIcon, SpinnerIcon } from "@panwds/icons";
import { Tooltip } from "@panwds/react-ui";
import { find, isEmpty } from "lodash";

interface Tag {
    Key: string;
    Value: string;
}

export const FIREWALL_STATE_FILTERS = {
    CREATING: 'Creating',
    UPDATING: 'Updating',
    DELETING: 'Deleting',
    CREATE_COMPLETE: 'Create Complete',
    UPDATE_COMPLETE: 'Update Complete',
    DELETE_COMPLETE: 'Delete Complete',
    CREATE_FAIL: 'Create Fail',
    UPDATE_FAIL: 'Update Fail',
    DELETE_FAIL: 'Delete Fail'
};

export const getPolicyManager = (record: Record<string, any>) => {
    if (record?.Firewall?.LinkId?.includes('Link-SCM')) {
        return `Strata Cloud Manager (${record?.Firewall?.LinkId})`
    } else if (record?.Firewall?.LinkId) {
        return `Panorama (${record?.Firewall?.LinkId})`
    } else if (record?.Firewall?.RuleStackName) {
        return `Local Rulestack (${record?.Firewall?.RuleStackName})`
    } else if (record?.Firewall?.GlobalRuleStackName) {
        return `Global Rulestack (${record?.Firewall?.GlobalRuleStackName})`
    } else {
        return '';
    }
};

export const getFirewallStateDisplay = (fwStatus: Record<string, any>) => {
    const state = fwStatus?.FirewallStatus;
    const failureReason = fwStatus?.FailureReason
    const lowerCaseState = state?.toLowerCase();
    const firewallFilterState = FIREWALL_STATE_FILTERS[state] ?? state;
    if (lowerCaseState?.includes('complete')) {
        return (<><CircleCheckIcon size="xs" className="tw-text-teal-600 dark:tw-text-dark-bg-teal"/> &nbsp; {firewallFilterState}</>);
    } else if (lowerCaseState?.includes('fail')) {
        return (
            <>
                <div className="tw-flex">
                    <Tooltip label={failureReason || ""}>
                        <ExclamationCircleIcon
                            size="xs"
                            className="tw-text-red-500 dark:tw-text-dark-bg-red"
                        />
                    </Tooltip>
                    &nbsp; {firewallFilterState}
                </div>
            </>
        );
    } else {
        return (<><SpinnerIcon size="xs" className="tw-text-orange-400 dark:tw-text-dark-bg-orange"/> &nbsp; {firewallFilterState}</>)
    }
};

export const getEndpointStateDisplay = (state: string) => {
    if (!state) {
        return <></>;
    }
    const lowerCaseState = state?.toLowerCase();
    if (lowerCaseState?.includes('accepted')) {
        return (<><CircleCheckIcon size="xs" className="tw-text-teal-600 dark:tw-text-dark-bg-teal"/> &nbsp; {state}</>);
    } else if (lowerCaseState?.includes('rejected')) {
        return (<><ExclamationCircleIcon size="xs" className="tw-text-red-500 dark:tw-text-dark-bg-red"/> &nbsp; {state} </>);
    } else {
        return (<><SpinnerIcon size="xs" className="tw-text-orange-400 dark:tw-text-dark-bg-orange"/> &nbsp; {state}</>)
    }
};

export const getFirewallName = (record: Record<string, any>) => {
    if (isEmpty(record)) {
        return '-';
    }

    // Check if Tags are directly under record or under record.Firewall
    const tags = record?.Firewall?.Tags || record?.Tags;

    if (!tags) {
        return '-';
    }

    const result = find(tags, (tag: Tag) => tag.Key === 'FirewallName') as Tag | undefined;

    return result ? result.Value : '-';
};

export const getFirewallTags = (tags: Record<string, any>, firewallName: string) => {
    if (isEmpty(tags) && isEmpty(firewallName)) {
        return [];
    }
    const index = tags.findIndex(tag => tag.Key === "FirewallName");
    if (index !== -1) {
        tags[index] = { ...tags[index], Value: firewallName };
    }
    else {
        tags.push({Key: "FirewallName", Value: firewallName})
    }

    return tags;
};

export const prepareData = (records: Record<string, any>[]) => {
    if (isEmpty(records)) {
        return [];
    }
    return records.map((record) => {
        return { ...record, Firewall: { ...record.Firewall, 'PolicyManager': getPolicyManager(record), 'FirewallName': getFirewallName(record)} }
    })
};

export const determinePolicyManaged = (firewallData: Record<string, any>): string => {
    if (firewallData?.Firewall?.LinkId?.includes('Link-SCM')) {
        return "Strata";
    } else if (firewallData?.Firewall?.LinkId) {
        return "Panorama";
    } else if (firewallData?.Firewall?.RuleStackName) {
        return "Rulestack";
    } else {
        return "Rulestack";
    }
};

export const formatChangeProtection = (changeProtection: string[]): string => {
    return changeProtection.map(item => {
        if (item === 'GlobalFirewallAdmin') {
            return 'Global Firewall Admin';
        } else if (item.includes('/LocalFirewallAdmin')) {
            const [accountId] = item.split('/');
            return `Local Firewall Admin (${accountId})`;
        } else {
            return item;
        }
    }).join(', ');
};

export const formatIPPoolType = (ipPoolType: string): string => {
    if (ipPoolType === 'AWSService') {
        return 'AWS Service IPs';
    }
    return ipPoolType; // For BYOIP and any other values, return as is
};

export const getLogsMetricsEndpoints = (logDestinationType?: string, logDestination?: string, type?: 'Logs' | 'Metrics', region?: 'string'): string => {
    //"S3" | "CloudWatchLogs" | "KinesisDataFirehose" | "metrics"
    let urlFormat = '';
    if(logDestinationType === 'CloudWatchLogs') {
        urlFormat = `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${logDestination}`;
    }
    if(logDestinationType === 'S3') {
        urlFormat = `https://s3.console.aws.amazon.com/s3/buckets/${logDestination}?region=${region}&tab=objects`;
    }
    if(logDestinationType === 'KinesisDataFirehose') {
        urlFormat = `https://${region}.console.aws.amazon.com/kinesis/home?region=${region}#/streams/details/${logDestination}/monitoring`;
    }
    if(type === 'Metrics' && logDestinationType !== undefined) {
        urlFormat = `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#metricsV2:graph=~()`;
    }
    return urlFormat;
}
